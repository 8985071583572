<template>
	<div class="conbox">
		<div class="sub_til" style="margin-top: 20px;">教师网络白名单-导入记录</div>
		<el-form :inline="true" :model="query" class="query-form" size="mini" style="float: left;">
			<el-form-item class="query-form-item">
				<el-select v-model="query.pihao" placeholder="批次">
					<el-option v-for="item in pihao" :key="item.key" :label="item.value" :value="item.key">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="query-form-item">
				<el-input v-model="query.mobile" placeholder="手机号"></el-input>
			</el-form-item>
			<el-form-item class="query-form-item">
				<el-input v-model="query.name" placeholder="姓名"></el-input>
			</el-form-item>
			<el-form-item class="query-form-item">
				<el-select v-model="query.status" placeholder="导入状态">
					<el-option v-for="item in status" :key="item.key" :label="item.value" :value="item.key">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button-group>
					<el-button type="primary" icon="el-icon-refresh" @click="onReset()"></el-button>
					<el-button type="primary" icon="search" @click="onSubmit()">查询</el-button>
					<el-button type="primary" @click="ResendForm(null, null)">一键重发</el-button>
				</el-button-group>
			</el-form-item>
		</el-form>
		<el-table v-loading="loading" :data="list" style="width: 100%" max-height="500" border>
			<el-table-column label="批次" prop="pihao"></el-table-column>
			<el-table-column label="手机号" prop="mobile"> </el-table-column>
			<el-table-column label="姓名" prop="name"> </el-table-column>
			<el-table-column label="导入状态" prop="status"> </el-table-column>
			<el-table-column label="操作" fixed="right">
				<template #default="scope">
					<el-button type="text" size="small" class="red" @click="handleResend(scope.$index, scope.row)">重发
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination background layout="total, prev, pager, next" :current-page="query.page"
				:page-size="query.pageSize" :total="total" @current-change="handlePageChange"></el-pagination>
		</div>
	</div>
</template>
<script>
	import {
		recordList,
		pihaoList,
		Resend,
		allResend
	} from "../api/teacherrecord";
	export default {
		data() {
			return {
				status: [{
						key: '',
						value: '请选择导入状态',
					}, {
						key: '0',
						value: '失败',
					},
					{
						key: '1',
						value: '成功',
					},
				],
				query: {
					page: 1,
					pageSize: 15,
				},
				list: [],
				pihao: [],
				total: 0,
				loading: true,
				deleteLoading: false,
			};
		},
		methods: {
			onReset() {
				this.$router.push({
					path: "",
				});
				this.query = {
					page: 1,
					pageSize: 15,
				};
				this.getList();
			},
			onSubmit() {
				this.$router.push({
					path: "",
					query: this.query,
				});
				this.getList();
			},
			handlePageChange(val) {
				this.query.page = val;
				this.getList();
			},
			getList() {
				this.loading = true;
				recordList(this.query)
					.then((res) => {
						if (res.code != 200) {
							this.$message.error(res.msg);
						}
						this.loading = false;
						console.log(res);
						this.list = res.data.data || [];
						this.total = res.data.total || 0;
					})
					.catch(() => {
						this.loading = false;
						this.list = [];
						this.total = 0;
					});
			},
			getpihaoList() {
				this.loading = true;
				pihaoList(this.query)
					.then((res) => {
						this.loading = false;
						console.log(res)
						this.pihao = res.data.pihao || [];
					})
					.catch(() => {
						this.loading = false;
						this.pihao = [];
					});
			},
			// 重发
			handleResend(index, row) {
				this.$confirm("您确定要重发该条记录吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						Resend({
							id: row.id
						}).then((res) => {
							// console.log(res);
							if (res.code == 200) {
								this.$message.success(res.msg);
								this.getList();
								return;
							} else {
								this.$message.error(res.msg);
								return;
							}
						});
					})
					.catch(() => {});
			},
			// 一键重发
			ResendForm() {
				this.$confirm("您确定要一键重发所有失败记录吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						// console.log(this.action);
						let data = {
							action: '6665',
						}
						allResend(data).then((res) => {
							console.log(res);
							if (res.code == 200) {
								this.$message.success(res.msg);
								this.getList();
								return;
							} else {
								this.$message.error(res.msg);
								return;
							}
						});
					})
					.catch(() => {});
			},
		},
		mounted() {},
		created() {
			// 加载表格数据
			// this.params = this.$route.query
			// this.getList();
			this.getpihaoList();
		},
	};
</script>

<style>
</style>
